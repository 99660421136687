import React from "react";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import { use100vh } from "react-div-100vh";
import { Link, Form, redirect } from "react-router-dom";
import TextInput from "../../components/TextInput";
import Image from "../../components/Image";

const SignIn = () => {
  const heightWindow = use100vh();

  const signIn = () => {
    const email = document.querySelector("input[name=email]").value;
    const password = document.querySelector("input[name=password]").value;
  };

  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        <Link className={styles.logo} to="/">
          <Image
            className={styles.pic}
            src="/images/logo-dark.png"
            srcDark="/images/logo-light.png"
            alt="Core"
          />
        </Link>
        <div className={cn("h2", styles.title)}>Sign in</div>

        <Form method="POST" className={styles.body}>
          <div className={styles.subtitle}>Sign in with email address</div>
          <TextInput
            className={styles.field}
            name="email"
            type="email"
            placeholder="Your email"
            required
            icon="mail"
          />
          <TextInput
            className={styles.field}
            name="password"
            type="password"
            placeholder="Password"
            required
            icon="lock"
          />
          <button className={cn("button", styles.button)}>Sign in</button>
          <div className={styles.note}>
            This site is protected by reCAPTCHA and the Google Privacy Policy.
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SignIn;

export async function action({ request }) {
  const data = await request.formData();
  const email = data.get("email");
  const password = data.get("password");

  console.log("Email: ", email);
  console.log("Password: ", password);

  const authResponse = await fetch(
    "https://api.bank11.io/auth/loginByPassword",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    }
  );

  console.log("STATUS: ", authResponse.status);

  const authR = await authResponse.json();
  console.log("authR: ", authR);

  if (authResponse.status === 200) {
    const { token } = authR.data;
    console.log("TOKEN: ", token);
  }

  return redirect("/");
}
