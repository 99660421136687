import React, { useState } from "react";
import cn from "classnames";
import useOnClickOutside from "use-onclickoutside";

import styles from "./Actions.module.sass";
import Icon from "../Icon";

const Actions = ({
  className,
  classActionsHead,
  classActionsBody,
  classActionsOption,
  items,
  visible,
  setVisible,
  small,
  up,
}) => {
  const [innerVisible, setInnerVisible] = useState(false);

  const toggle = () => {
    setVisible ? setVisible(!visible) : setInnerVisible(!innerVisible);
  };
  const ref = React.useRef(null);
  useOnClickOutside(ref, () => {
    setVisible ? setVisible(false) : setInnerVisible(false);
  });

  return (
    <div
      ref={ref}
      className={cn(
        styles.actions,
        className,
        {
          [styles.small]: small,
        },
        {
          [styles.up]: up,
        },
        {
          [styles.active]: visible ? visible : innerVisible,
        }
      )}
    >
      <button
        className={cn(styles.head, classActionsHead)}
        onClick={() => toggle()}
      >
        <Icon name="more-horizontal" size="24" />
      </button>
      <div className={cn(styles.body, classActionsBody)}>
        {items.map((x, index) => (
          <button
            className={cn(styles.option, classActionsOption)}
            onClick={x.action}
            key={index}
          >
            {x.icon && <Icon name={x.icon} size="24" />}
            {x.title}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Actions;
