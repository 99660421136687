import {
  // BrowserRouter as Router,
  // Route,
  RouterProvider,
  createBrowserRouter,
  // createRoutesFromElements,
  // redirect,
} from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
// import ProductsDashboard from "./screens/ProductsDashboard";
// import NewProduct from "./screens/NewProduct";
// import Drafts from "./screens/Drafts";
// import Released from "./screens/Released";
// import Comments from "./screens/Comments";
// import Scheduled from "./screens/Scheduled";
// import Customers from "./screens/Customers";
// import CustomerList from "./screens/CustomerList";
// import Promote from "./screens/Promote";
// import Notification from "./screens/Notification";
// import Settings from "./screens/Settings";
// import UpgradeToPro from "./screens/UpgradeToPro";
// import MessageCenter from "./screens/MessageCenter";
// import ExploreCreators from "./screens/ExploreCreators";
// import AffiliateCenter from "./screens/AffiliateCenter";
// import SignUp from "./screens/SignUp";
import SignIn, { action as authAction } from "./screens/SignIn";
// import Earning from "./screens/Earning";
// import Refunds from "./screens/Refunds";
// import Payouts from "./screens/Payouts";
// import Statements from "./screens/Statements";
// import Shop from "./screens/Shop";
// import PageList from "./screens/PageList";
// import LoginForm from "./screens/LoginForm";

function App() {
  const router2 = createBrowserRouter([
    {
      path: "/",
      element: (
        <Page title="Dashboard">
          <Home />
        </Page>
      ),
    },
    {
      path: "/login",
      element: <SignIn />,
      action: authAction,
    },
    {
      path: "/customers",
    },
  ]);

  return <RouterProvider router={router2} />;
}

export default App;
